<template>
  <div class="temp-home">
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="">
          <h1 class="fade-in-bottom">
            Dra. Ruth Lizetth <br />
            Robles Rodríguez
          </h1>
          <h2 class="subtitle fade-in-bottom">Gastroenterología</h2>

          <a
            class="fade-in-bottom button is-large"
            v-on:click="modalActive = !modalActive"
          >
            contacto
          </a>

          <div class="direccion is-bottom fade-in-bottom">
            <p>
              <a
                href="tel:+525559207527"
                target="_blank"
              >
                Citas al &nbsp; +52 55 5920 7527
              </a>
            </p>
            <p>
              <a
                href="https://www.cedulaprofesional.sep.gob.mx/cedula/presidencia/indexAvanzada.action"
                target="_blank"
              >
                Céd. Prof. 8443655 &nbsp; Universidad de Monterrey
              </a>
            </p>

            <p>
              <a
                href="https://www.cedulaprofesional.sep.gob.mx/cedula/presidencia/indexAvanzada.action"
                target="_blank"
              >
                Céd. Esp. 11502230 &nbsp; Universidad Nacional Autónoma de
                México
              </a>
            </p>
          </div>
        </div>
      </div>
      <img src="@/assets/estomago.svg" alt="logo" id="icon-logo" />
    </section>

    <div class="section modal" v-bind:class="{ 'is-active': modalActive }">
      <div
        class="modal-background"
        v-on:click="modalActive = !modalActive"
      ></div>

      <div class="modal-content fade-in-bottom">
        <h3>Vamos a platicar</h3>

        <div class="columns">
          <div class="column">
            <div class="direccion">
              <p>
                <a href="https://goo.gl/maps/d8Ee3aFvXVZR9HDP8" target="_blank">
                  Av. Pirineos 160, <br />
                  Col. Portales Norte, C.P. 03300, <br />
                  Ciudad de México
                </a>
              </p>
            </div>
            <form
              action="https://formspree.io/dra.rrobles@gmail.com"
              method="POST"
            >
              <div class="field">
                <label class="label">Nombre</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="Aquí va tu nombre"
                    value=""
                    required
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">Email</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="email"
                    placeholder="Escribe aqui tu correo"
                    value=""
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label class="label">Mensaje</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    placeholder="Escribe aqui tu mensaje"
                    required
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                value="send"
                class="button is-large is-info"
              >
                Enviar.
              </button>
            </form>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        v-on:click="modalActive = !modalActive"
        aria-label="close"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "tempHome",
  props: {
    msg: String,
  },
  data() {
    return {
      // Initialized to zero to begin
      modalActive: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/_variables.scss";

$blue: #374a9cff;

* {
  color: black;
}
img#icon-logo {
  position: absolute;
  height: 50vh;
  right: 0;
  padding-right: 20px;
  top: 0px;
  z-index: -100;
  opacity: 0.2;

  @include media(">tablet") {
    right: 2rem;
    opacity: 1;
    height: calc(100vh - 20px);
    object-fit: cover;
  }
}
.modal-content {
  max-height: 100%;
  background-color: $white;
  border-radius: 8px;
  padding: 2rem;
  overflow-x: hidden;
}
.direccion {
  padding-bottom: 20px;

  & p,
  & a {
    font-family: "Space Mono", monospace;
    color: $blue;
    margin-bottom: 20px;
    font-size: ms(0);
  }

  & a {
    background-image: linear-gradient(
      to right,
      $blue 10%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
  }
}

.subtitle {
  color: black;
  font-size: ms(2);
  font-family: Fira Sans, "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;

  @include media(">tablet") {
    font-size: ms(3);
  }
}
.is-bottom {
  position: absolute;
  bottom: 0px;

  @include media(">phone", "<tablet") {
    font-size: 10px;
    padding-right: 20px;
  }
}
.button.is-info {
  background-color: $blue;
}

button.button {
  font-family: "Space Mono", monospace !important;
}
label.label {
  font-weight: lighter;
}

p {
  text-align: left;
}

h1 {
  font-size: ms(5);
  line-height: 1;
  text-align: left;
  font-weight: bolder;
  animation-name: fadeInUp;

  @include media(">phone", "<tablet") {
    font-size: ms(3);
    color: $blue;
  }
}

h3 {
  font-size: ms(3);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2rem;
  color: $blue;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-4-15 22:9:30
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.fade-in-bottom {
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
</style>