<template>
  <div class="home">
    
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->

    <tempHome />

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import tempHome from "@/components/temp-home.vue";

export default {
  name: "home",
  components: {
    // HelloWorld,
    tempHome
  }
};
</script>

<style lang="scss">
 * {
   text-align: left;
 }

</style>
